import Vue from 'vue';
import App from './App.vue';
import Buefy from 'buefy';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import { routes } from './routes';
import { store } from './store/store';
import { HTTP, HTTPContent } from './utils/http-common';
import moment from 'moment';
import { c2g } from './utils/c2g';
import { helper } from './utils/helper';
import VueScrollTo from 'vue-scrollto';

Vue.config.productionTip = false;
Vue.prototype.$http = HTTP;
Vue.prototype.$httpContent = HTTPContent;
Vue.prototype.$moment = moment;
Vue.prototype.$c2g = c2g;
Vue.prototype.$helper = helper;

Vue.use(VueRouter);
Vue.use(Buefy);
Vue.use(VueScrollTo);
Vue.use(Meta);

// Build router
const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Redirect old certificates
  if (to.path.startsWith('/Certificates/Travel Insurance')) {
    window.location.href = to.path.replace(
      '/Certificates/Travel Insurance',
      '/api/certificates'
    );
  } else {
    // Replace title tag with meta value
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next();
  }
});

// Configuration VueAnalytics
Vue.use(
  router
);

// Initialise Vue
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
