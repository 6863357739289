export const maintenance = {
    data() {
        return { check: '1' }
    },
    methods: {
        // Check to see if page is down for maintenance
        async maintenanceCheck() {
            let result = await this.$http.get('scheme/maintenance_check');
            this.check = await result.data;

            if (await this.check === 0) {
                this.$router.push({name: 'maintenance'});
            }
        },
    }
};