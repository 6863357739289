const Home = () => import("./views/Home");

const GetQuote = () => import(/* webpackChunkName: "quoteJourney" */"./views/GetQuote");
const PolicyDetails = () => import(/* webpackChunkName: "quoteJourney" */"./views/PolicyDetails");
const YourDetails = () => import(/* webpackChunkName: "quoteJourney" */"./views/YourDetails");
const PurchasePolicy = () => import(/* webpackChunkName: "quoteJourney" */"./views/PurchasePolicy");

const AboutUs = () => import("./views/AboutUs");
const ContactUs = () => import("./views/ContactUs");
const Reviews = () => import("./views/Reviews");
const Confirmation = () => import("./views/Confirmation");
const Faqs = () => import("./views/Faqs");
const PolicyWordings = () => import("./views/PolicyWordings");
const PolicyWordingsMUL = () => import("./views/PolicyWordingsMUL");
const PolicyIPID = () => import("./views/PolicyIPID");
const Claim = () => import("./views/Claim");
// const WhichArea = () => import("./views/WhichArea");
// const ActivityPacks = () => import("./views/ActivityPacks");
const SingleTrip = () => import("./views/SingleTrip");
const AnnualTrip = () => import("./views/AnnualTrip");
const Defaqto = () => import("./views/Defaqto");
const LongStay = () => import("./views/LongStay");
const CruiseCover = () => import("./views/CruiseCover");
const WinterSportsCover = () => import("./views/WinterSportsCover");
const WinterSportsTravelInsurance = () => import("./views/WinterSportsTravelInsurance");
const UKCover = () => import("./views/UKTravelInsurance");
const PageNotFound = () => import("./views/PageNotFound");
const PreExistingMedicalConditions = () => import("./views/PreExistingMedicalConditions");
const Covid19Cover = () => import("./views/Covid19Cover");
const SportsActivitiesCover = () => import("./views/SportsActivitiesCover");
const CompareCoverLevels = () => import("./views/CompareCoverLevels");
const PrivacyPolicy = () => import("./views/PrivacyPolicy");
const CookiePolicy = () => import("./views/CookiePolicy");
const RefundPolicy = () => import("./views/RefundPolicy");
const Blog = () => import("./views/Blog");
const BlogView = () => import("./views/BlogView");
const PolicyRedirect = () => import("./views/PolicyRedirect");
const CustomerHub = () => import("./views/CustomerHub");
const CreateAccount = () => import("./views/CreateAccount");
const ConfirmEmail = () => import("./views/ConfirmEmail");
const EuropeCover = () => import("./views/EuropeTravelInsurance");
const WorldwideCover = () => import("./views/WorldwideTravelInsurance");
const CruiseTravelInsurance = () => import("./views/CruiseTravelInsurance");
const BusinessTravelInsurance = () => import("./views/BusinessTravelInsurance");
const FamilyTravelInsurance = () => import("./views/FamilyTravelInsurance");
const GroupTravelInsurance = () => import("./views/GroupTravelInsurance");
const GolfTravelInsurance = () => import("./views/GolfTravelInsurance");
const TravelByDestination = () => import("./views/TravelByDestination");
const DestinationDubai = () => import("./views/DestinationDubai");
const DestinationSpain = () => import("./views/DestinationSpain");
const DestinationUSA = () => import("./views/DestinationUSA");
const Over65s = () => import("./views/Over65s");
const NonUKResidents = () => import("./views/NonUKResidents");
const TravelInsuranceHelp = () => import("./views/TravelInsuranceHelp");
const HolidayChecklist = () => import("./views/HolidayChecklist");
const NeedTravelInsurance = () => import("./views/NeedTravelInsurance");
const CustomerHome = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerHome");
const CustomerPolicies = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerPolicies");
const CustomerQuotes = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerQuotes");
const CustomerProfile = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerProfile");
const CustomerProfileEdit = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerProfileEdit");
const CustomerPolicy = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerPolicy");
const CustomerMedicalScreening = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerMedicalScreening");
const CustomerPolicyWording = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerPolicyWording");
const CustomerContactUs = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerContactUs");
const CustomerFeedback = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerFeedback");
const CustomerChangePassword = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerChangePassword");
const CustomerPolicyUpgrade = () => import(/* webpackChunkName: "customerHub" */"./components/customer-hub/sections/CustomerPolicyUpgrade");
const MapsDirectory = () => import("./views/MapsDirectory");
// import CustomerPolicyUpgradeSuccess from "./components/customer-hub/sections/upgrade/CustomerUpgradeSuccess";
// import CustomerPolicyUpgradeFailed from "./components/customer-hub/sections/upgrade/CustomerUpgradeFailed";
const ForgotPassword = () => import("./views/ForgotPassword");
const UnsubscribeEmail = () => import("./views/UnsubscribeEmail");
const Maintenance = () => import("./views/Maintenance");

const Affiliates = () => import("./views/Affiliates");

export const routes = [
    { path: '', name: 'home', component: Home, meta: { title: 'Covered2go: “Excellent” Rated Travel Insurance' }, props: true },

    { path: '/profile/:affiliate', name: 'affiliates', component: Affiliates, meta: { title: 'Affiliates | Covered2Go' }  },

    { path: '/covid-19-travel-insurance', name: 'covid19Cover', component: Covid19Cover, meta: { title: 'Covid-19 Travel Insurance | Covered2Go' } },
    { path: '/blog/covid-19-update', redirect: { name: 'covid19Cover' }},
    { path: '/blog/covid-19-claims-faqs-helpful-advice', name: 'covid19Cover', component: Covid19Cover, meta: { title: 'Covid-19 Travel Insurance | Covered2Go' } },

    { path: '/get-quote', name: 'getQuote', component: GetQuote, meta: { title: 'Quote | Covered2Go' } },
    { path: '/quote-results', name: 'policyDetails', component: PolicyDetails, meta: { title: 'Results | Covered2Go' } },
    { path: '/your-details', name: 'yourDetails', component: YourDetails, meta: { title: 'Details | Covered2Go' } },
    { path: '/purchase-policy', name: 'purchasePolicy', component: PurchasePolicy, meta: { title: 'Purchase | Covered2Go' } },
    { path: '/confirmation', name: 'confirmation', component: Confirmation, meta: { title: 'Confirmation | Covered2Go' } },

    { path: '/about-us', name: 'aboutUs', component: AboutUs, meta: { title: 'About Us | Covered2Go' } },
    { path: '/contact-us', name: 'contactUs', component: ContactUs, meta: { title: 'Contact Us | Covered2Go' } },
    { path: '/reviews', name: 'reviews', component: Reviews, meta: { title: 'Reviews | Covered2Go' } },
    { path: '/blog-posts', name: 'blog', component: Blog, meta: { title: 'Blog Posts | Covered2Go' } },
    { path: '/defaqto', name: 'defaqto', component: Defaqto, meta: { title: 'Our Defaqto Star Ratings | Covered2Go' } },
    { path: '/faqs', name: 'faqs', component: Faqs, meta: { title: 'FAQs | Covered2Go' } },
    { path: '/policy-wordings', name: 'policyWordings', component: PolicyWordings, meta: { title: 'Policy Wordings | Covered2Go' } },
    { path: '/policy-wordings-mul', name: 'policyWordingsMUL', component: PolicyWordingsMUL, meta: { title: 'Policy Wordings | Covered2Go' } },
    { path: '/claim', name: 'claim', component: Claim, meta: { title: 'Claim | Covered2Go' } },
    // { path: '/which-area', name: 'whichArea', component: WhichArea, meta: { title: 'Which Area? | Covered2Go' } },
    // { path: '/activity-packs', name: 'activityPacks', component: ActivityPacks, meta: { title: 'Activity Packs | Covered2Go' } },
    { path: '/single-trip-travel-insurance', name: 'singleTrip', component: SingleTrip, meta: { title: 'Single Trip | Covered2Go' } },
    { path: '/annual-trip-travel-insurance', name: 'annualTrip', component: AnnualTrip, meta: { title: 'Annual Trip | Covered2Go' } },
    { path: '/longstay-travel-insurance', name: 'longstayTrip', component: LongStay, meta: { title: 'Longstay Trip | Covered2Go' } },
    { path: '/cruise-cover', name: 'cruiseCover', component: CruiseCover, meta: { title: 'Cruise Cover | Covered2Go' } },
    { path: '/winter-sports-cover', name: 'winterSportsCover', component: WinterSportsCover, meta: { title: 'Winter Sports | Covered2Go' } },
    { path: '/pre-existing-medical-conditions', name: 'medicalConditions', component: PreExistingMedicalConditions, meta: { title: 'Medical Conditions | Covered2Go' } },
    { path: '/uk-travel-insurance', name: 'ukCover', component: UKCover, meta: { title: 'UK Travel Insurance | Covered2Go' } },
    { path: '/europe-travel-insurance', name: 'europeCover', component: EuropeCover, meta: { title: 'Europe Travel Insurance | Covered2Go' } },
    { path: '/worldwide-travel-insurance', name: 'worldwideTravelInsurance', component: WorldwideCover, meta: { title: 'Worldwide Travel Insurance | Covered2Go' } },
    { path: '/winter-sports-travel-insurance', name: 'winterSportsTravelInsurance', component: WinterSportsTravelInsurance, meta: { title: 'Winter Sports Travel Insurance | Covered2Go' } },
    { path: '/cruise-travel-insurance', name: 'cruiseTravelInsurance', component: CruiseTravelInsurance, meta: { title: 'Cruise Travel Insurance | Covered2Go' } },
    { path: '/business-travel-insurance', name: 'businessTravelInsurance', component: BusinessTravelInsurance, meta: { title: 'Business Travel Insurance | Covered2Go' } },
    { path: '/family-travel-insurance', name: 'familyTravelInsurance', component: FamilyTravelInsurance, meta: { title: 'Family Travel Insurance | Covered2Go' } },
    { path: '/group-travel-insurance', name: 'groupTravelInsurance', component: GroupTravelInsurance, meta: { title: 'Group Travel Insurance | Covered2Go' } },
    { path: '/golf-travel-insurance', name: 'golfTravelInsurance', component: GolfTravelInsurance, meta: { title: 'Golf Travel Insurance | Covered2Go' } },
    { path: '/maps-directory', name: 'mapsDirectory', component: MapsDirectory, meta: { title: 'MaPS Directory | Covered2Go' } },
    { path: '/covid19-travel-insurance', name: 'covid19Cover', component: Covid19Cover, meta: { title: 'Covid-19 Travel Insurance | Covered2Go' } },
    { path: '/sports-activities-cover', name: 'sportsActivitiesCover', component: SportsActivitiesCover, meta: { title: 'Sports & Activities | Covered2Go' } },
    { path: '/compare-cover-levels', name: 'compareCoverLevels', component: CompareCoverLevels, meta: { title: 'Compare Cover Levels | Covered2Go' } },
    { path: '/travel-by-destination', name: 'travelByDestination', component: TravelByDestination, meta: { title: 'Travel By Destination | Covered2Go' } },
    { path: '/do-i-need-travel-insurance', name: 'needTravelInsurance', component: NeedTravelInsurance, meta: { title: 'Do I need Travel Insurance? | Covered2Go' } },
    { path: '/over-65s-travel-insurance', name: 'over65s', component: Over65s, meta: { title: 'Over 65s Travel Insurance | Covered2Go' } },
    { path: '/non-uk-residents', name: 'nonUKResidents', component: NonUKResidents, meta: { title: 'Travel Insurance for Non UK Residents | Covered2Go' } },
    { path: '/travel-insurance-help', name: 'travelInsuranceHelp', component: TravelInsuranceHelp, meta: { title: 'Travel Insurance Help | Covered2Go' } },
    { path: '/holiday-checklist', name: 'holidayChecklist', component: HolidayChecklist, meta: { title: 'Holiday Checklist | Covered2Go' } },
    { path: '/dubai-travel-insurance', name: 'destinationDubai', component: DestinationDubai, meta: { title: 'Dubai Travel Insurance | Covered2Go' } },
    { path: '/spain-travel-insurance', name: 'destinationSpain', component: DestinationSpain, meta: { title: 'Spain Travel Insurance | Covered2Go' } },
    { path: '/usa-travel-insurance', name: 'destinationUSA', component: DestinationUSA, meta: { title: 'USA Travel Insurance | Covered2Go' } },

    { path: '/privacy-policy', name: 'privacyPolicy', component: PrivacyPolicy, meta: { title: 'Privacy Policy | Covered2Go' } },
    { path: '/cookie-policy', name: 'cookiePolicy', component: CookiePolicy, meta: { title: 'Cookie Policy | Covered2Go' } },
    { path: '/refund-policy', name: 'refundPolicy', component: RefundPolicy, meta: { title: 'Refund Policy | Covered2Go' } },
    { path: '/policy-ipids', name: 'policyIPID', component: PolicyIPID, meta: { title: 'Policy IPID | Covered2Go' } },
    { path: '/create-account', name: 'createAccount', component: CreateAccount, meta: { title: 'Create Account | Covered2Go' } },
    { path: '/forgot-password', name: 'forgotPassword', component: ForgotPassword, meta: { title: 'Forgot Password | Covered2Go' } },
    { path: '/forgot-password/:code', name: 'forgotPassword', component: ForgotPassword, meta: { title: 'Confirm New Password | Covered2Go' }, props: true },
    { path: '/confirm_email/:id', name: 'confirmEmail', component: ConfirmEmail, meta: { title: 'Confirm Account | Covered2Go' }, props: true },
    { path: '/unsubscribe/:id', name: 'unsubscribeEmail', component: UnsubscribeEmail, meta: { title: 'Unsubscribe | Covered2Go' }, props: true },
    { path: '/policy/:id', name: 'policyRedirect', component: PolicyRedirect, meta: { title: 'Redirecting.. | Covered2Go' }, props: true },
    { path: '/blog/:id', name: 'blogView', component: BlogView, meta: { title: 'Blog | Covered2Go' }, props: true },

    { path: '/customer-hub', name: 'customerHub', component: CustomerHub, meta: { title: 'Customer Hub | Covered2Go' },
        children: [
            { path: '', name: 'customerHome', component: CustomerHome,  meta: { title: 'Your Home | Covered2Go' } },
            { path: 'policies', name: 'customerPolicies', component: CustomerPolicies,  meta: { title: 'Your Policies | Covered2Go' } },
            { path: 'quotes', name: 'customerQuotes', component: CustomerQuotes,  meta: { title: 'Your Quotes | Covered2Go' } },
            { path: 'profile', name: 'customerProfile', component: CustomerProfile,  meta: { title: 'Your Profile | Covered2Go' } },
            { path: 'profile-edit', name: 'customerProfileEdit', component: CustomerProfileEdit,  meta: { title: 'Edit Your Profile | Covered2Go' } },
            { path: 'policy/:id', name: 'customerPolicy', component: CustomerPolicy,  meta: { title: 'Policy | Covered2Go' }, props: true },
            { path: 'medical-screening', name: 'customerMedicalScreening', component: CustomerMedicalScreening,  meta: { title: 'Medical Screening | Covered2Go' } },
            { path: 'policy-wording', name: 'customerPolicyWording', component: CustomerPolicyWording,  meta: { title: 'Policy Wordings | Covered2Go' } },
            { path: 'contact-us', name: 'customerContactUs', component: CustomerContactUs,  meta: { title: 'Contact Us | Covered2Go' } },
            { path: 'feedback', name: 'customerFeedback', component: CustomerFeedback,  meta: { title: 'Feedback | Covered2Go' } },
            { path: 'change-password', name: 'customerChangePassword', component: CustomerChangePassword,  meta: { title: 'Change Password | Covered2Go' } },
            { path: 'policy-update/:id', name: 'customerUpgradePolicy', component: CustomerPolicyUpgrade,  meta: { title: 'Policy Upgrade | Covered2Go' }, props: true },
            // { path: 'policy-update/success', name: 'customerUpgradePolicySuccess', component: CustomerPolicyUpgradeSuccess,  meta: { title: 'Upgrade Success | Covered2Go' } },
            // { path: 'policy-update/failed', name: 'customerUpgradePolicyFailed', component: CustomerPolicyUpgradeFailed,  meta: { title: 'Upgrade Failed | Covered2Go' } },
        ]
    },

    { path: '/travel-insurance/policy-wordings/*', redirect: { name: 'policyWordings' }},
    { path: '/travel-insurance', redirect: { name: 'home' }},
    { path: '/travel-insurance/*', redirect: { name: 'home' }},
    { path: '/product-upgrades-coming-soon', name: 'maintenance', component: Maintenance, meta: { title: 'Product Upgrades Coming Soon | Covered2Go' }},
    { path: '*', name: 'pageNotFound', component: PageNotFound, meta: { title: '404 | Covered2Go' } },
];

