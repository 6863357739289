<template>
    <footer class="footer">

        <!-- <div class="column" style="font-size: 12px; text-align: center; padding-top: 30px;">
            <p>Covered2go Travel Insurance trading as</p>
            <p>Rush Insurance Services Limited are members of B.I.B.A</p>
            <p>(British Insurance Brokers' Association) and A.T.I.I.</p>
            <p>(Association Travel Insurance Intermediaries)</p>
        </div> -->

        <div class="content has-text-centered has-text-white">
            <div class="columns">
                <div class="column">
                    <p class="col-head">Site Map:</p>
                    <router-link :to="{name:'home'}">Home</router-link>
                    <router-link :to="{name:'contactUs'}">Contact Us</router-link>
                    <router-link :to="{name:'faqs'}">FAQs</router-link>
                    <router-link :to="{name:'privacyPolicy'}">Privacy policy</router-link>
                    <router-link :to="{name:'cookiePolicy'}">Cookie policy</router-link>
                    <router-link :to="{name:'refundPolicy'}">Refund policy</router-link>

                    <div style="text-align: center; margin-top: 20px;">Secure Payment<br>We are PCIDSS compliant</div>
                    <div class="columns is-mobile">
                        <div class="column is-half is-offset-one-quarter">
                            <div class="level">
                                <div class="level-item">
                                    <picture>
                                        <source srcset="../assets/images/security_icon.webp" type="image/webp">
                                        <source srcset="../assets/images/security_icon.png" type="image/png">
                                        <img src="../assets/images/security_icon.webp" alt="Shield Icon" loading="lazy" width="70" />
                                    </picture>
                                </div>
                                <div class="level-item">
                                    <picture>
                                        <source srcset="../assets/images/visa_icon.webp" type="image/webp">
                                        <source srcset="../assets/images/visa_icon.png" type="image/png">
                                        <img src="../assets/images/visa_icon.webp" alt="Visa Logo" loading="lazy" width="70" />
                                    </picture>
                                </div>
                                <div class="level-item">
                                    <picture>
                                        <source srcset="../assets/images/mastercard_icon.webp" type="image/webp">
                                        <source srcset="../assets/images/mastercard_icon.png" type="image/png">
                                        <img src="../assets/images/mastercard_icon.webp" alt="Mastercard Logo" loading="lazy" width="70" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <p class="col-head">Useful Contacts:</p>
                    <p>Policy Sales: 0333 400 7745</p>
                    <p>Claims +44 (0)330 660 0785</p>
                    <a href="mailto:enquiries@covered2go.co.uk">Email: enquiries@covered2go.co.uk</a>
                    <router-link to="contact-us">Click here for Claims and Assistance </router-link>
                    <div class="social">
                        <a href="https://www.facebook.com/Covered2go" target="_blank"><i class="fab fa-facebook-square"/></a>
                        <a href="https://twitter.com/covered2go" target="_blank"><i class="fab fa-twitter"/></a>
                        <a href="https://www.instagram.com/covered2go" target="_blank"><i class="fab fa-instagram"/></a>
                    </div>
                    <div class="level" style="padding-left: 60px; padding-right: 60px; margin-top: 30px;">
                        <div class="level-item">
                            <picture>
                                <source srcset="../assets/images/atii_logo.webp" type="image/webp">
                                <source srcset="../assets/images/atii_logo.png" type="image/png">
                                <img src="../assets/images/atii_logo.webp" alt="ATIL Logo" loading="lazy" style="min-width: 130px; height: 70px;" />
                            </picture>
                        </div>
                        <div class="level-item">
                            <picture>
                                <source srcset="../assets/images/rush_logo.webp" type="image/webp">
                                <source srcset="../assets/images/rush_logo.png" type="image/png">
                                <img src="../assets/images/rush_logo.webp" alt="Rush Insurance Logo" loading="lazy" style="min-width: 60px; height: 70px" />
                            </picture>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <p class="col-head">Useful Links:</p>
                    <router-link :to="{name:'annualTrip'}">Annual Multi Trip Travel Insurance</router-link>
                    <router-link :to="{name:'singleTrip'}">Single Trip Travel Insurance</router-link>
                    <router-link :to="{name:'longstayTrip'}">Longstay Travel Insurance</router-link>
                    <router-link :to="{name:'policyWordingsMUL'}">Policy Wordings</router-link>
                    <router-link :to="{name:'policyIPID'}">Insurance Product Information Documents</router-link>
                    <a :href="tobLink" target="_blank">Terms of Business</a>
                    <a href="javascript:;" @click="siteData.customerLoginModalActive = true">Customer Hub</a>
                </div>
            </div>
            <hr>
            <div class="content bottom-info">
                <p>
                    This insurance is provided by Covered2Go which is a trading name of Rush Insurance Services Limited who are authorised and regulated by the Financial Conduct Authority (Firm Reference Number 714385) and which is permitted to arrange general insurance contracts.
                </p>
                <p>
                    Copyright © Covered2Go Travel Insurance 2023
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
    import { policyAPI } from "../mixins/policy-api";

    export default {
        name: "Footer",
        data() {
            return {
                sdLink: '',
                tobLink: ''
            }
        },
        props: ['siteData'],
        mixins: [ policyAPI ],
        mounted() {
            // Get the status disclosure link
            this.statusDisclosureLink().then((res) => {
                this.sdLink = res.data;
            });
            // Get the terms of business link
            this.termsOfBusinessLink().then((res) => {
                this.tobLink = res.data;
            });
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        margin-top: 100px;
        position: relative;

        .col-head {
            border-top: 2px white solid;
            border-bottom: 2px white solid;
            //margin-top: 15px;
            padding: 5px;
        }
        a {
            display: block;
            margin-bottom: 3px !important;
            color: white;
            transition: 0.4s;
        }
        a:hover {
            text-decoration: underline;
            color: $c2g_orange;
        }
        p {
            margin-bottom: 3px !important;
        }
        .social {
            margin-top: 15px;
            a {
                margin-right: 15px;
                font-size: 30px;
                display: inline-block;
                color: white;
                transition: 0.4s;
            }
            a:hover {
                color: $c2g_orange;
            }
        }
        .bottom-info {
            text-align: center;
            color: white;
            p {
                margin-top: 10px;
                font-size: 13px;
            }
        }
    }
</style>
