export const getters = {
    // Get the policy in it's current state
    policy: state => {
        return state.policy;
    },
    // Policy errors which are more technical
    policySystemErrors: state => {
        if(!state.policy) {
            return false;
        }
        return Object.values(state.policy)[0].system;
    },
    // Policy errors visible for the customer
    policyCustomerErrors: state => {
        if(!state.policy) {
            return false;
        }
        return Object.values(state.policy)[0].customer;
    },

    // Get the requested policy
    reqPolicy: state => {
        return state.reqPolicy;
    },
    // Get the up-sell policy in it's current state
    upsellPolicy: state => {
        return state.upsellPolicy;
    },

    // Get the call centre state value
    callCentre: state => {
        return state.callCentre;
    },

    // Get the call centre state value
    affiliation: state => {
        if (state.affiliation == null) {
            const name = 'profile' + "=";
            const cDecoded = decodeURIComponent(document.cookie); //to be careful
            const cArr = cDecoded.split('; ');
            let res;
            cArr.forEach(val => {
                if (val.trim().indexOf(name) === 0) res = val.substring(name.length);
            })
            return res;
        }
        return state.affiliation;
    },

    // Get the customer state value
    customer: state => {
        return state.customer;
    },
};